/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Header from "./header"
import "./layout.scss"
import { Helmet } from "react-helmet"
import { changeLocale } from "gatsby-plugin-intl"
import { PlayerContext } from "./audioPlayerWrapper"
import { graphql, useStaticQuery } from "gatsby"
import * as R from "ramda"
import { IMeta } from "../types/models"

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en"
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0]
  if (!lang) return "en"

  switch (lang) {
    case "ja":
      return "ja"
    case "zh":
      return "zh"
    default:
      return "en"
  }
}

const Layout = ({ children }: any) => {
  React.useEffect(() => {
    if (!!localStorage.getItem("hem_lang")) {
      return
    }
    const urlLang = getRedirectLanguage()
    const userSelected = localStorage.getItem("hem_lang")
    changeLocale(userSelected || urlLang)
    if (!userSelected) {
      localStorage.setItem("hem_lang", urlLang)
    }
  }, [])

  const meta = useStaticQuery<IMeta>(graphql`
    {
      strapiAbout {
        en
        ja
        zh
      }
      strapiMeta {
        contact {
          Bandcamp
          Spotify
          Twitter
          Weibo
          YouTube
          Instagram
        }
      }
    }
  `)

  const consumer = React.useContext(PlayerContext)
  const getContact = (key: string) =>
    R.pathOr("", ["strapiMeta", "contact", key], meta)
  const isRadioPage = !!R.path<any>(["location", "pathname"])(window).match(
    /[en|ja|zh]{2}\/radio/
  )

  return (
    <div
      id="hem-container"
      className={`flex ${consumer.hidePlayer ? "" : "player-placeholder"}`}
    >
      <Header />
      <Helmet>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta
          content="https://hem-production-hk.oss-cn-hongkong.aliyuncs.com/assets/og-image.png"
          property="og:image"
        />
        <script
          defer
          data-domain="hem.fm"
          src="https://static.dinehq.cn/js/script.outbound-links.js"
        ></script>
      </Helmet>
      <div className="wrapper">{children}</div>
      <div
        className={`hem-footer grid grid-cols-1 md:grid-cols-2 ${
          isRadioPage ? "bg-black" : ""
        }`}
      >
        <div
          className={`order-2 md:order-1 hem-footer-copyright text-left ABC flex justify-center flex-col`}
        >
          <div className="">&copy; {new Date().getFullYear()} HEM Records</div>
        </div>
        <div
          className={`order-1 md:order-2 hem-footer-links w-full flex justify-between items-center ABC`}
        >
          <a
            target="_blank"
            href={getContact("Bandcamp")}
            className="hem-external-link"
          >
            <span>Bandcamp</span>
          </a>
          <a
            target="_blank"
            href={getContact("Instagram")}
            className="hem-external-link"
          >
            <span>Instagram</span>
          </a>
          <a
            target="_blank"
            href={getContact("Twitter")}
            className="hem-external-link"
          >
            <span>Twitter</span>
          </a>
          <a
            target="_blank"
            href={getContact("Weibo")}
            className="hem-external-link"
          >
            <span>Weibo</span>
          </a>
          {/* <a
            target="_blank"
            href={getContact("YouTube")}
            className="hem-external-link"
          >
            <span>YouTube</span>
          </a> */}
        </div>
      </div>
    </div>
  )
}

export default Layout
